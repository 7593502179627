import React from "react"
import RichText from "../../../new-ui/rich-text"
import { Container } from "./elements"
import styled from "@emotion/styled"

const TextContainer = styled.div`
  width: 100%;

  .rich-text {
    h2 {
      font-size: 24px;
    }

    b {
      font-weight: normal;
      color: ${(props) => props.theme.colors.primary};
    }

    ul {
      li {
        list-style: none;

        p {
          margin-bottom: 0em;
        }
      }
    }

    p {
      margin-bottom: 0.6em;
    }

    a {
      color: inherit;
      text-decoration: underline;
    }

    hr {
      margin: 0 20px;
      background-color: ${(props) => props.theme.colors.grayscale[700]};
    }

    h2,
    p,
    h6,
    .accordion {
      padding: 0 20px;
    }

    .accordion {
      margin: 10px 0;

      .content {
        line-height: 1.4;

        p {
          padding: 10px 10%;
        }
      }

      .accordion-header {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        border-bottom: ${(props) => props.theme.borders.dark};
        padding: 12px 0;

        span {
          font-size: 24px;
          line-height: 1.2;
          padding-right: 10px;
        }

        .icon {
          width: 30px;
          height: 18px;
          margin-right: 0px;
        }
      }
    }
  }

  ${(props) => props.theme.breakpointsLegacy.desktop} {
    .rich-text {
      h2,
      p,
      h6,
      .accordion {
        padding: 0 80px;
      }

      hr {
        margin: 0 80px;
      }
    }
  }
`

const BlogContent = ({ input }) => {
  const hasText = input.text && input.text.raw
  const hasImages = input.images && !!input.images.length

  return (
    <Container>
      {hasText && (
        <TextContainer hasImages={hasImages}>
          <RichText input={{ body: input.text }} />
        </TextContainer>
      )}
    </Container>
  )
}

export default BlogContent

import styled from "@emotion/styled"
import Typography from "../../../ui/Typography"

export const TextContainer = styled.div`
  width: 100%;
`

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: ${(props) => props.theme.maxWidth};
  margin: auto;
  margin-bottom: 60px;
  justify-content: space-between;
  align-items: center;

  &:first-of-type {
    padding-top: 40px;
  }

  padding-left: 20px;
  padding-right: 20px;

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    padding: 0 8%;
  }
`

export const Body = styled.div`
  ${Typography.Base}

  color: ${(props) =>
    props.isTextLightMobile
      ? props.theme.colors.grayscale[300]
      : props.theme.colors.primary};

  ${(props) => props.theme.breakpointsLegacy.desktop} {
    color: ${(props) =>
      props.isTextLightDesktop
        ? props.theme.colors.grayscale[300]
        : props.theme.colors.primary};
  }

  a {
    color: inherit;
    text-decoration: none;
    border-bottom: 1px solid ${(props) => props.theme.colors.grayscale[300]};
    &:hover {
      border-bottom: 1px solid ${(props) => props.theme.colors.grayscale[700]};
    }
  }

  ul {
    list-style: "●";
    li {
      padding-left: 1rem;
      strong {
        color: ${(props) =>
          props.isTextLightMobile
            ? props.theme.colors.grayscale.white
            : props.theme.colors.primary};

        ${(props) => props.theme.breakpointsLegacy.desktop} {
          color: ${(props) =>
            props.isTextLightDesktop
              ? props.theme.colors.grayscale.white
              : props.theme.colors.primary};
        }
        font-weight: 300;
      }
    }
  }

  strong {
    font-weight: 300;
    color: ${(props) =>
      props.isTextLightMobile
        ? props.theme.colors.grayscale.white
        : props.theme.colors.primary};

    ${(props) => props.theme.breakpointsLegacy.desktop} {
      color: ${(props) =>
        props.isTextLightDesktop
          ? props.theme.colors.grayscale.white
          : props.theme.colors.primary};
    }
  }

  h3 {
    ${Typography.Base}

    margin-bottom: 1rem;
    font-weight: 300;

    color: ${(props) =>
      props.isTextLightMobile
        ? props.theme.colors.grayscale.white
        : props.theme.colors.primary};

    ${(props) => props.theme.breakpointsLegacy.desktop} {
      color: ${(props) =>
        props.isTextLightDesktop
          ? props.theme.colors.grayscale.white
          : props.theme.colors.primary};
    }
  }

  p {
    margin-bottom: 2rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

export default Body
